import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Paper } from '@material-ui/core';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
  p10: {
    padding: '10px'
  }
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '50px',
  ':hover': {
    boxShadow: 20,
  },
  color: theme.palette.text.secondary,
}));
export const HomePage = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.p10}>
      <Grid item xs={12}>
        <h1>Welcome to uLab System's Developer Portal!</h1>
      </Grid>
      {/* <Grid container spacing={2} xs={12}  className={classes.p10}>
        <Grid item xs={12}>
          <h2>Documentation</h2>
        </Grid>
        <Grid item xs={4}>
        <a href="https://ulabsystems.atlassian.net/wiki/spaces/UE/overview" target="_blank"><Item elevation={1}>Confluence</Item></a>
        </Grid>
      </Grid> */}
      <Grid container spacing={2} xs={12}  className={classes.p10}>
        <Grid item xs={12}>
          <h2>Monitoring Tools</h2>
        </Grid>
        <Grid item xs={4}>
        <a href="https://status.ulabsystems.net/" target="_blank"><Item elevation={1}>Status.IO</Item></a>
        </Grid>
        <Grid item xs={4}>
        <a href="https://logs.ulabsystems.net" target="_blank"><Item elevation={1}>Centralized Logging System</Item></a>
        </Grid>
        <Grid item xs={4}>
        <a href="https://qube.ulabsystems.io/" target="_blank"><Item elevation={1}>SonarQube</Item></a>
        </Grid>
        <Grid item xs={4}>
        <a href="https://k8s.dev.aligneroem.com/" target="_blank"><Item elevation={1}>Nucleas Platform Monitoring - Dev</Item></a>
        </Grid>
        <Grid item xs={4}>
        <a href="https://k8s.qa.aligneroem.com/" target="_blank"><Item elevation={1}>Nucleas Platform Monitoring - QA</Item></a>
        </Grid>
        <Grid item xs={4}>
        <a href="https://k8s.aligneroem.com/" target="_blank"><Item elevation={1}>Nucleas Platform Monitoring - Prod</Item></a>
        </Grid>
        <Grid item xs={4}>
        <a href="https://k8s.aligneroem.com/grafana/dashboards" target="_blank"><Item elevation={1}>Grafana Dashboards - Prod</Item></a>
        </Grid>
        <Grid item xs={4}>
        <a href="https://ulabsystems.app.opsgenie.com/" target="_blank"><Item elevation={1}>OpsGenie</Item></a>
        </Grid>
      </Grid>
    </Grid>
  )
};